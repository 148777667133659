export enum TeaserType {
	BASIC_AND_PLUS_TEASER = 'BasicAndPlus',
	UPGRADE_TEASER = 'Upgrade',
	PLUS_RENEW_TEASER = 'Renewal',
	PLUS_EXPIRY_TEASER = 'Expiry',
	VIP_UPGRADE_TEASER = 'VipUpgrade',
	VIP_RENEW_TEASER = 'VipRenewal',
	VIP_EXPIRY_TEASER = 'VipExpiry',
	NO_CONTENT = 'NoContent',
	ACHIEVEMENT_TEASER = 'Achievement',
	TO_LOGIN = 'UnidentifiedUser',
	TO_COMPLETE_LIGHTWEIGHT_CUSTOMER_DATA = 'ToCompleteLightweightCustomerData'
}

export const hasAnOttoUpPackage = (teaserType: TeaserType): boolean => {
	return teaserType !== TeaserType.BASIC_AND_PLUS_TEASER;
};

export const hasBasic = (teaserType: TeaserType): boolean => {
	return teaserType === TeaserType.UPGRADE_TEASER || teaserType === TeaserType.PLUS_RENEW_TEASER;
};

export const hasPlus = (teaserType: TeaserType): boolean => {
	return teaserType === TeaserType.PLUS_EXPIRY_TEASER;
};

export const isVip = (teaserType: TeaserType): boolean => {
	return teaserType === TeaserType.VIP_EXPIRY_TEASER ||
		teaserType === TeaserType.VIP_RENEW_TEASER ||
		teaserType === TeaserType.VIP_UPGRADE_TEASER;
};
